function setSurveyCookie() {
    document.cookie = "maintenanceNotif=true; path=/"
  }
  
  function getCookieName(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    //if (parts.length === 2) return parts.pop().split(';').shift();
    if (!document.cookie.split('; ').find(row => row.startsWith(name))) {
      return false;
    } else {
      return true;
    }
  }
  
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  
  if (getCookieName("maintenanceNotif") == false) {
    $(document).ready(function(){
        $('.survey-close').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          setSurveyCookie();
          $('#downtimeNotice').modal('hide');
        });
        $("#downtimeNotice").modal({backdrop: 'static'});
    });
  }
